<template>
    <v-container fluid v-if="data.length != 0">
        <grid :columns="GetColumns()"
              :data="GetDataItems()"
              @rowclicked="RowClicked($event)"
              @RowButtonClicked="RowButtonClicked($event)"
              :attributes="GetFormsAttributes"
              addrow=""
              :showExportToExcelButton="false"
              :clickrow="1 == 1"
              :HideHeader="true"
              :itemsPerPageProp="5"/>
    </v-container>
</template>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var forms = {
        tableName: 'forms',
        label: '',
        columns: {
            id: {
                type: 'int',
                label: 'FL_FormNo',
                edit: '',
                name: 'id',
                width: 0
            },
            subject: {
                type: 'text',
                name: 'subject',
                label: 'FL_Subject',
                edit: '',
                width: 40,
            },
            formname: {
                type: 'hidden',
                name: 'formname',
                label: 'FL_FormName',
                edit: '',
                width: 30,
            },
            view: {
                type: 'button',
                label: 'AL_View',
                name: 'view',
                edit: '',
                width: 10,
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    import { mapGetters } from 'vuex';
    import grid from '@/components/grid.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';

    export default {
        name: 'actionexpectedforms',
        mixins: [FormMixIn],
        components: {
            grid
        },
        props: {
            dataItem: { type: Array, default: function () { return [] } }            
        },
        data: function () {
            return {
                data: [],
            };
        },
        methods: {
            GetColumns: function () {
                return forms.columns;
            },
            GetDataItems: function () {
                return this.dataItem;
            },
            FormLoaded: function ()
            {
                return;
            },

            Selected: function ()
            {
                return;
            },

            FormButtonClicked(col) {
                alert(col);
                var name = col.name;

            },

            RowClicked: function (rowdata, table) 
            {
                return;
            },

            RowButtonClicked: function (col) {
                var name = col.item.formname + 'form';
                this.$router.push({
                    name: name,
                    params: { id: col.item.id }
                });
            },

            ChangeAttribute: function (attribute, tableName)
            {
                return;
            },
            LoadData: function () {
                var payload = {
                    queryName: 'ActionExpectedForms',
                    parameters: {}
                };
                var self = this;
                this.$store.dispatch('GetLovDynamic', payload).then(response => {
                    self.data = response.data;
                });
            },
        },

        mounted: function () {
            this.data = this.dataItem;
        },
        computed: {
            GetFormsAttributes: function () {
                return forms.attributes;
            },
        },
        watch: {
        }
    };
</script>
