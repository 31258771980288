<template>
    <div class="pie-chart-Container">
        <div style="width:35%">
            <figure class="pie-chart" :style="{'background': backgroundImage }">
            </figure>
        </div>
        <div style="width:65%">
            <div style="">
                <div class="pointer" v-for="(item, index) in dataItems" @click="legendClicked(item)">
                    <div v-if="item.Inchart == 1" style="display:flex">
                        <div style="width: 87%; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"><span v-if="item.Inchart == 1" :style="{'color':colors[item.ActionType]}"></span> {{ $t(item.title) }}:</div>
                        <!--<div style="width:2%">:</div>-->
                        <div style="width:13%; text-align:right"><b>{{ item.formCount }}</b></div>
                         
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .pie-chart {
        
        position: relative;
        width: 140px;
        min-height: 150px;
        margin: 0;
        
    }
        .pie-chart figcaption {
            position: absolute;
            bottom: 1em;
            right: 1em;
            font-size: smaller;
            text-align: right;
        }

    .pie-chart-Container {
        display:flex;
    }

    .pie-chart-Container .pointer {
        cursor:pointer;
    }

    .pie-chart-Container span:after {
        display: inline-block;
        content: "";
        width: 0.8em;
        height: 0.8em;
        margin-left: 0.4em;
        height: 0.8em;
        border-radius: 0.2em;
        background: currentColor;
    }
</style>
<script>

    import Utilities from '@/Utilities.js';
    import { FormMixIn } from '@/MixIns/FormMixins';

    export default {
        name: "history",
        data: function () {
            return {
                survey: [],
                openallpanel: [],
                oonaynots: [],
                value: '',
                oonayad: '',
                oonaysicilno: '',
                oonayTitle: '',
                canSelectApprover: true,
                colors: { bank: '#ea1429', supplier: '#fd7e14', active: '#00ADB5', completed: '#a9b1b2', all:'#a9b1b2'},
                items: [{ text: 'bekleyen', percentage: 20, value: 5 }, { text: 'giden', percentage: 20, value: 7 }, { text: 'gelen', percentage: 60, value: 51 }, { text: 'hepsi', percentage: 0, value: 55, notInChart: true }],
                
            };
        },
        mixins: [FormMixIn],
        props: {
            params: Object,
            invalue: String,
            editable: Boolean,
            approved: Boolean,
            label: String,
            column: Object,
            error: Boolean,
            dataItems: Array,
            menuItem: Object,
            isPortal: { type: Boolean, default: false },
        },
        computed:
        {
            backgroundImage: function () {
                var sum = 0;
                var text = '';
                for (var i = 0; i < this.dataItems.length; i++)
                {
                    text += this.colors[this.dataItems[i].ActionType] + ' ' + sum + '%, ' + this.colors[this.dataItems[i].ActionType] + ' ' + (sum + this.dataItems[i].percentage) + '%';
                    if (i + 1 != this.dataItems.length) {
                        text += ", ";
                    }
                    sum = sum + this.dataItems[i].percentage;
                }
                return 'radial-gradient( circle closest-side, transparent 66%, white 0 ), conic-gradient( ' + text + ' )';
            },
        },
        mounted: function () {
            
        },

        methods:
        {
            legendClicked: function (item) {
                this.$router.push({
                    name: this.menuItem.link,
                    params: {  }
                });
            }
        },
        watch: {
        },
        filters: {

        }
    }
</script>
