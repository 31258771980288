<template>
    <div>
        <v-main v-if="!loading" fluid class="mt-n6">
            <v-container class="pa-8">
                <v-row>
                    <v-col v-show="!tedarikciapproved" cols="12" md="12">
                        <v-alert prominent border="left"
                                 dense
                                 rounded
                                 type="warning" class="mb-0 pl-6 v-alert--dark">
                            {{$t('IM_CandidateDupplier')}}
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-for="item in items" :key="item.index" v-show="!isHomePage(item.link)" cols="12" md="4" v-if="(item.link == 'tedarik' && !tedarikciapproved) || (item.whatToShow != 'noList' && tedarikciapproved)">
                        <!--<v-alert :icon="item.icon" prominent border="left"
                     rounded
                     colored-border
                     depressed color="primary" style="background-color:white" class="mb-0 pl-6 v-alert--white">
                <v-row align="center">
                    <v-col class="grow secondary--text">
                        {{ $t(item.title) }}
                    </v-col>
                    <v-col align="right">
                        <v-btn v-show="item.link == 'tedarik' || tedarikciapproved" tile text :to="{name: item.link}" color="secondary">
                            {{ $t("AL_GoToPage") }} <v-icon right>mdi-arrow-right-bold</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        12
                    </v-col>
                </v-row>
            </v-alert>-->
                        <v-card class="v-card--material--has-heading" style="margin-top: 5px;">
                            <v-card-title class="justify-end">
                                <div class="text-start v-card--material__heading mb-n6 v-sheet theme--dark elevation-6 pa-1" style="background-color: #a9b1b2 !important; border-color: #a9b1b2 !important; ">
                                    {{ $t(item.title) }}
                                </div>
                            </v-card-title>
                            <v-card-text class="text-left" style="">
                                <v-btn v-if="item.innerItems.length == 0" v-show="item.link == 'tedarik' || tedarikciapproved" tile text :to="{name: item.link}" color="secondary">
                                    {{ $t("AL_GoToPage") }} <v-icon right>mdi-arrow-right-bold</v-icon>
                                </v-btn>
                                <pieChart v-if="item.innerItems.length > 0" :dataItems="item.innerItems" :menuItem="item"></pieChart>
                                <!--{{ $t("FormInfoDesc_"+ item.link) }}-->
                            </v-card-text>
                            <!--<v-card-text class="text-center font-weight-bold" style="cursor:pointer" v-if="item.forms.length != 0" @click="showActionExpectedForm(item)">
                                {{ $t("ActionWaiting") }} {{ $t(item.title) }} ({{ item.forms.length }})
                                <v-icon right style="font-size: 18px;" v-if="item.show == true">mdi-arrow-up</v-icon>
                                <v-icon right style="font-size: 18px;" v-if="item.show == false">mdi-arrow-down</v-icon>
                            </v-card-text>
                            <ActionExpectedForms :dataItem="item.forms" v-show="item.show"></ActionExpectedForms>-->
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-show="tedarikciapproved">
                    <v-col cols="6" md="6">
                        <teklifList></teklifList>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <v-main v-else fluid class="mt-n6">
            <v-container class="pa-8">
                <v-row>
                    <v-col v-for="i in 10" :key="i" cols="12" md="6">
                        <v-alert icon="a"
                                 prominent border="left"
                                 rounded
                                 colored-border
                                 depressed color="primary" style="background-color:white" class="mb-0 pl-6 v-alert--white">
                            <v-row align="center">
                                <v-col class="grow secondary--text">
                                    <v-skeleton-loader class="mr-auto"
                                                       max-height="20"
                                                       max-width="360"
                                                       type="image"></v-skeleton-loader>
                                </v-col>
                                <v-col align="right">
                                    <v-skeleton-loader class="ml-auto"
                                                       max-height="20"
                                                       max-width="120"
                                                       type="image"></v-skeleton-loader>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>
<style scoped>
    a {
        text-decoration: none;
    }

    .v-alert--text.v-alert--white:before {
        background-color: white;
    }
</style>
<script>
    import Vue from "vue"
    import Vuex from "vuex"
    import Chart from 'chart.js'
    Vue.use(Vuex);
    import { mapGetters } from "vuex";
    import dynoform from "@/components/DynoForm.vue";
    import grid from "@/components/grid.vue";
    import teklifList from "@/components/TeklifList.vue";
    import ActionExpectedForms from "@/components/ActionExpectedForms.vue";
    import leftmenu from "@/components/leftmenu.vue";
    import kview from "@/components/view.vue";
    import axios from "axios";
    import _ from "lodash";
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from "@/MixIns/FormMixins";
    import store from '@/store'
    import accordionmenu from "@/components/AccordionMenu.vue";
    import pieChart from "@/components/pieChart.vue";
    import Utilities from '@/Utilities.js'
    //import { extname } from "path";
    function ad_AE(payload)
    {
        /*alert('AE')*/
    }
    export default {
        name: "home",
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            accordionmenu,
            kview,
            teklifList,
            ActionExpectedForms,
            pieChart
        },
        data: function ()
        {
            return {
                drawer: null,
                valid: false,
                lazy: true,
                username: '',
                viewName: '',
                form: '',
                tedarikciapproved: false,
                validateError: '',
                items: [],
                loading: true
            };
        },
        computed: {

        },
        methods: {
            isHomePage(link)
            {
                return (link === '' || link === 'home' || link === 'anasayfa');
            },
            showActionExpectedForm(menuItem) {
                if (menuItem.show != true) {
                    menuItem.show = true;
                }
                else {
                    menuItem.show = false;
                }
            }
        },
        mounted: function ()
        {
            this.loading = true;
            Utilities.log("ruternameee ::::: ", this.$route);
            Utilities.log('mount menu');
            this.$store
                .dispatch('GetAccordionMenu')
                .then(menu =>
                {
                    Utilities.log('Got menu', menu);
                    this.items = menu;
                    this.tedarikciapproved = this.GetUserInfo.approved;
                    this.loading = false;
                })
                .catch(error =>
                {
                    this.ShowDialog(this.$root.$i18n.t('EM_UnexpectedErrorTryAgain'), 'E', 'dialog');
                    this.loading = false;
                });
        }
    }
</script>
