<template>
    <v-container fluid v-if="data.length != 0">
        <grid :columns="GetColumns()"
              :data="GetDataItems()"
              :header="$t('TH_OpenTendersDidNotBid')"
              @rowclicked="RowClicked($event)"
              @RowButtonClicked="RowButtonClicked($event)"
              :attributes="GetTeklifAttributes"
              addrow=""
              :showExportToExcelButton="false"
              :clickrow="1 == 1"
              :itemsPerPageProp="5" />
    </v-container>
</template>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var teklifList = {
        tableName: 'siparisler',
        label: 'TH_OpenTendersDidNotBid',
        columns: {
            konu: {
                type: 'text',
                name: 'konu',
                label: 'FL_Subject',
                edit: '',
                width: 40,
            },
            sontarih: {
                type: 'date',
                name: 'sontarih',
                label: 'FL_LastDate',
                edit: '',
                width: 30,
            },
            sonsaat: {
                type: 'text',
                name: 'sonsaat',
                label: 'FL_LastTime',
                edit: '',
                width: 20,
            },
            teklifver: {
                type: 'button',
                label: 'FL_Bid',
                name: 'teklifver',
                edit: '01X2',
                width: 10,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    import { mapGetters } from 'vuex';
    import grid from '@/components/grid.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';

    export default {
        name: 'teklifList',
        mixins: [FormMixIn],
        components: {
            grid
        },
        props: {
        },
        data: function () {
            return {
                data: [],
            };
        },
        methods: {
            GetColumns: function () {
                return teklifList.columns;
            },
            GetDataItems: function () {
                return this.data;
            },
            FormLoaded: function ()
            {
                return;
            },

            Selected: function ()
            {
                return;
            },

            FormButtonClicked(col) {
                alert(col);
                var name = col.name;

            },

            RowClicked: function (rowdata, table)
            {
                return;
            },

            RowButtonClicked: function (col) {
                    this.$router.push({
                        name: "gelenteklifform",
                        params: { id: 0 }, query: { teklifId: col.item.id }
                    });
            },

            ChangeAttribute: function (attribute, tableName)
            {
                return;
            },
            LoadData: function () {
                var payload = {
                    queryName: 'TeklifList',
                    parameters: {}
                };
                var self = this;
                this.$store.dispatch('GetLovDynamic', payload).then(response => {
                    self.data = response.data;
                });
            },
        },


        mounted: function () {
            this.LoadData();
        },
        computed: {
            DynamicFormInfo: function () {
                return dynamic_formInfo;
            },
            GetTeklifAttributes: function () {
                return teklifList.attributes;
            },
        },
        watch: {
            parameters: {
                handler: function (val, oldVal) {
                    var a = val.showFormDialog;
                    this.modalFormId = val.modalFormId;
                },
                deep: true
            },
            modalFormId: function (nval, oldval) {
                if (nval == 0) {
                    this.CreateNewForm();
                }
                else if (nval == -1) {

                }
                else {
                    this.LoadUserForm(nval);
                }
            }
        }
    };
</script>
